<template>
  <div>
    <div id="content">
      <div style="position:relative;">
        <div class="overlay" :class="{ vidOver: page[0].acf.banner_image__video === 'video'}">
          <template v-if="page[0].acf.banner_image__video === 'video'">
            <div class='mobHide'>
            <video class='vid' muted autoplay='1' loop='0'>
              <source :src="page[0].acf.video">
            </video>
            </div>
            <div class='mobShow'>
              <b-img class="splash" :src="page[0].acf.banner_image"></b-img>
            </div>
          </template>
          <template v-else>
            <b-img class="splash" :src="page[0].acf.banner_image"></b-img>
          </template>
        </div>
      </div>
      <div class="HPbanner hideMob">
        <template>
          <b-link :href="page[0].acf.banner_link_homepage">
            <b-img :src="page[0].acf.banner_image_homepage"></b-img>
          </b-link>
        </template>
      </div>
      <div class="ourContainer">
        <div class="title">
          <h1 class="blockText">
            <span class="well" v-html="page[0].acf.text_one">
            </span>
            <!-- <span class="scot" v-html="page[0].acf.text_two">
            </span> -->
            <span class="tag" v-html="page[0].acf.text_three">
            </span>
          </h1>
          <template v-if="page[0].acf.text_four_url.length > 0">
            <b-button target="_blank" :href="page[0].acf.text_four_url" class="chooseHref one"
              v-html="page[0].acf.text_four"></b-button
            >
          </template>
          <template v-else>
            <b-button @click="scroll()" class="choose"
              v-html="page[0].acf.text_four"></b-button
            >
          </template>
        </div>
      </div>
      <div class="HPbanner showDesk">
        <template>
          <b-link :href="page[0].acf.banner_link_homepage">
            <b-img :src="page[0].acf.banner_image_homepage"></b-img>
          </b-link>
        </template>
      </div>
      <div class="ourContainer">
        <div>
          <News cat="none" :newsPage="newsPage" />
        </div>
        <b-row class="viewNewsRow">
          <b-col class="">
            <b-nav-item class="viewAllNews" to="/news">View All News</b-nav-item>
          </b-col>
        </b-row>
        <div style="margin-bottom:33px;" class="padding ourContainer eventsContainer" >
          <b-row>
            <b-col>
              <h2 class="upcomingEvents">
                {{page[0].acf.events_title}}
              </h2>
            </b-col>
          </b-row>
          <UpComing discipline="none" total="two" />
          <b-row class="viewEventsRow">
            <b-col class="">
              <b-nav-item class="viewAllNews" to="/events">View All Events</b-nav-item>
            </b-col>
          </b-row>
        </div>
      </div>
      <div class="pad HomeOurSportContainer" style="padding: 66px; background: #eefcff;">
          <div class="ourContainer">
            <span id="discip" class="anchor"></span>
            <b-row class="">
              <b-col>
                <h2 class="upcomingEvents">
                  Our Sports
                </h2>
              </b-col>
            </b-row>
            <Disciplines />
          </div>

      </div>
      <div class="joinSwimback" v-bind:style="{ 'background-image': 'url(' + 'https://ocs-sport.ams3.cdn.digitaloceanspaces.com/scotswim-full/2022/05/Join-Our-Swimming-Community.jpg' + ')' }">
      <!-- <div class="joinSwimback" v-bind:style="{ 'background-color': '#2d2dcf' }"> -->
        <div class="container joinSwimBlock">
          <h1>
            <span class="well" v-html="page[0].acf.home_text_two">
            </span>
            <span class="Jointag" v-html="page[0].acf.home_text_three">
            </span>
          </h1>
          <div class="JoinSwimbuttonsBlock">
            <div class="joinSwimButtons">Find Out How</div>
            <div class="joinSwimButtons">
              <a href="https://scottishswimming.justgo.com/Account.mvc/Login?ReturnUrl=%2f">Sign In</a>
            </div>
          </div>
        </div>
      </div>

      <div class="homeBottomSection" v-bind:style="{ 'background-image': 'url(' + 'https://ocs-sport.ams3.cdn.digitaloceanspaces.com/scotswim-full/2022/05/JobsContact-BG.jpg' + ')' }">
        <b-row class="container innerhomeBottomSection">
          <b-col cols="12" lg="6" md="6" class="innerhomeBottomSection-inner">
            <div class="bottomHomeCard">
              <b-card :title="page[0].acf.home_bottom_section_first_title">
                <b-card-text v-html="page[0].acf.home_bottom_section_first_description"></b-card-text>
                <a :href="page[0].acf.home_bottom_section_first_link" class="card-link">See Jobs</a>
              </b-card>
            </div>
          </b-col>
          <b-col cols="12" lg="6" md="6" class="innerhomeBottomSection-inner">
            <div class="bottomHomeCard">
              <b-card :title="page[0].acf.home_bottom_section_second_title">
                <b-card-text v-html="page[0].acf.home_bottom_section_second_description"></b-card-text>
                <a :href="page[0].acf.home_bottom_section_second_link" class="card-link">Read More</a>
              </b-card>
            </div>
          </b-col>
        </b-row>
      </div>

      <!-- <template v-if="page[0].acf.highlight_area === true">
        <div class="ourContainer highlightMob" style="padding: 66px; padding-top:0px">
          <Highlighted />
        </div>
      </template> -->


      <!-- <template v-if="page[0].acf.news_letter_sign_up === true">
        <div>
          <SignUp />homeBottomSection
        </div>
      </template> -->
    </div>   
    <Footer /> 
  </div>

</template>

<script>
import apiCall from "@/services/api.js";
import Disciplines from "@/components/disciplines.vue";
import UpComing from "@/components/upComing.vue";
import News from "@/components/news.vue";
import Footer from "@/components/footer.vue";
// import SignUp from "@/components/signUp.vue";
// import Highlighted from "@/components/highlighted.vue";
export default {
  name: "home",
  components: { 
    Footer,
    // Highlighted, 
    Disciplines, 
    UpComing, 
    News, 
    // SignUp 
  },
  data() {
    return {
      page: [],
      newsPage: false,
      title: '',
      description: ''
    };
  },
  metaInfo() {
    return {
      title: this.title,
      meta: [
        { charset: 'utf-8' },
        { name: 'description', content: this.description },
      ]
    }
  },
  methods: {
    scroll() {
      var discip = document.getElementById("discip");
      discip.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    },
  },
  mounted() {
    apiCall
      .wpInfo("wp/v2/pages?slug=" + this.$route.name)
      .then(({ data }) => {
        this.page = data;
        this.title = data[0].title.rendered;
        this.description = data[0].yoast_head_json.og_description;
        this.loading = false;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
};
</script>

<style scoped>
.HPbanner {
  margin-top: -16px;
}
.bottomHomeCard > .card {
  border: 0px solid rgba(0, 0, 0, 0.125);
  height: 200px;
}
.joinSwimButtons > a:hover {
  text-decoration: none;
}
a.card-link:hover {
  font-weight: 600;
  font-size: 15px;
  color: #007bff;
  text-decoration: underline;
}
.joinSwimButtons > a {
  color: #fff;
}
h1.blockText {
  text-align: left;
  /* max-width: 1350px; */
  margin: 0 auto;
  padding: 0 30px;
  font-family: GT-Haptik-Medium;
}
a.card-link {
  font-weight: 600;
  font-size: 15px;
  color: #201546;
  text-decoration: underline;
}
p.card-text {
  font-size: 16px;
  color: #201546;
}
h4.card-title {
  font-weight: 600;
  font-size: 18px;
}
.innerhomeBottomSection {
  margin: auto;
  padding-top: 75px;
}
.JoinSwimbuttonsBlock {
  display: inline-flex;
}
.joinSwimButtons{
  background: #db044e;
  color: #fff;
  width: 150px;
  border-radius: 8px;
  margin: auto;
  padding: 0.5rem 1rem;
  margin-right: 5px;
  margin-left: 5px;
}
.joinSwimButtons:hover{
  background: rgb(0, 159, 227);
  color: #fff;
  width: 150px;
  border-radius: 8px;
  margin: auto;
  padding: 0.5rem 1rem;
  margin-right: 5px;
  margin-left: 5px;
  cursor: pointer;
}
.Jointag {
  font-weight: normal;
  font-size: 1.2rem;
  width: 40%;
  padding-bottom: 20px;
  display: block;
  margin: auto;
  line-height: 1.4;
}
.joinSwimBlock {
  text-align: center;
  color: #fff;
  padding-top: 130px;
}
.homeBottomSection {
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 340px;
}
.joinSwimback {
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 500px;
}
.viewEventsRow {
  margin-bottom: 55px;
  margin-top: 50px;
}
.viewNewsRow {
  margin-bottom: 100px;
}
li.nav-item.viewAllNews {
  background: #db044e;
  color: #fff;
  width: 150px;
  border-radius: 8px;
  margin: auto;
}
li.nav-item.viewAllNews:hover {
  background: rgb(0, 159, 227);
  color: #fff;
  width: 150px;
  border-radius: 8px;
  margin: auto;
  cursor: pointer;
}
li.nav-item.viewAllNews > a {
  color: #fff;
  font-weight: 600;
  text-align: center;
}
.homeSectionText {
  text-align: left;
  padding-left: 60px;
}
::v-deep .grey {
  background-color: transparent;
}
h2.upcomingEvents {
  /* font-weight: bold; */
  /* margin-bottom: 40px; */
  margin-bottom: 15px;
  font-family: GT-Haptik-Medium;
  color: #1f1545;
  font-size: 2.5rem;
}
.btn-secondary {
  border-color: transparent;
}
#content {
  padding-left: 20%;
}
.overlay {
  background-color: #201546;
  background-image: url(https://ocs-sport.ams3.cdn.digitaloceanspaces.com/scotswim-full/2021/10/HomePage_Overlay_1@2x.png);
  background-repeat: no-repeat;
  background-size: cover;
}
.splash {
  /* opacity: 0.46; */
  object-fit: cover;
  height: 700px;
}
.title {
  position: absolute;
  /* top: 36%; */
  /* top: 10%; */
  margin-top: -17em;
  width: 100%;
  text-align: center;
  height: 75px;
  color: #fff;
  text-align: center;
  letter-spacing: 0px;
  text-shadow: 0px 3px 6px #00000058;

  text-shadow: 0px 3px 6px #0000005a;
}
.well {
  /* font-weight: 600; */
  font-size: 2.5rem;
  font-family: GT-Haptik-Medium;
}
.scot {
  font-weight: bold;
  font-size: 3.5rem;
}
.tag {
  font-weight: normal;
  font-size: 1.2rem;
  width: 40%;
  padding-bottom: 18px;
  display: block;
  line-height: 1.4;
}
.chooseHref {
  background-color: #db044e;
  border-radius: 7px;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  /* margin-top: 10px; */
  margin-top: -5px;
  text-shadow: none;
  display: block;
  margin-left: 30px;
  text-align: left;
  float: left;
  margin-left: 30px;
}
.chooseHref:hover{
  background-color: #009fe3;
}
::v-deep .chooseHref > p {
  margin-bottom: 0px!important;
}
.choose {
  background-color: #db044e;
  border-radius: 7px;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  /* margin-top: 10px; */
  margin-top: -5px;
  text-shadow: none;
  display: block;
  margin-left: 30px;
}
.choose:hover{
  background-color: #009fe3;
}
::v-deep .choose > p {
  margin-bottom: 0px!important;
}
.icon {
  margin: auto;
  margin-top: 50px;
  display: block;
}
.ourContainer {
  max-width: 1350px;
  margin: 0 auto;
  padding: 0 30px;
}
.pad {
  padding-top: 35px;
}
.all {
  text-align: right;
  padding-right: 20px;
}
.all > a {
  color: #009fe3;
  text-decoration: underline;
  font-weight: bold;
}
.all:hover{
  /* font-weight: bold; */
}
.vid{
  width: 100%;
  opacity: 0.46;
  display: block;
}
.mobShow{
  display: none;
}
@media only screen and (max-width: 1525px) {
  .title{
    /* top: 38%; */
    margin-top: -19em;
    padding-bottom: 20em;
    position: relative;
  }
}
@media screen and (max-width: 1366px) and (min-width: 1200px) {
  .title{
    /* top: 38%; */
    margin-top: -19em;
    padding-bottom: 20em;
    position: relative;
  }
}
@media only screen and (max-width: 1325px) {
.title{
  top: 38%;
}
}
@media only screen and (max-width: 1175px) {
  .tag {
    font-weight: normal;
    font-size: 1.2rem;
    width: 100%;
    padding-bottom: 18px;
    display: block;
    line-height: 1.4;
  }
.title{
  top: 37%;
}
}
@media only screen and (min-width: 991px) {
  .HideDesk {
    display: none;
  }
}
@media only screen and (max-width: 990px) {
  .homeBottomSection {
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: auto;
  }
  .innerhomeBottomSection-inner {
    margin-bottom: 30px;
  }
  h1.blockText {
    text-align: left;
    margin: 0 auto;
    padding: 0 30px;
    /* margin-top: 50px; */
    /* margin-top: -132px; */
    margin-top: 0px;
    margin-left: 0px;
    padding-left: 0px;
    font-family: GT-Haptik-Medium;
  }
  .splash {
    /* opacity: 0.46; */
    object-fit: cover;
    height: 300px;
  }
  .highlightMob {
    padding: 0px 43px 66px!important;
  }
  .HomeOurSportContainer {
    padding: 30px!important;
  }
  .eventsContainer {
    padding: 0 15px!important;
  }
  .HideMob {
    display: none;
  }
  #content {
    padding-left: 0;
  }
  .title {
    /* top: 20%; */
    margin-top: -16em;
  }
  .well {
    font-size: 1.5rem;
  }
  .scot {
    font-size: 2.5rem;
  }
  ::v-deep span.scot > p {
    margin-bottom: 0px!important;
  }
  .tag {
    font-size: 1rem;
    line-height: 1.5;
    display: inline-table;
    width: 100%;
    padding-bottom: 0px;
    /* padding-bottom: 45px; */
  }
  .choose {
    font-size: 14px;
    margin-top: 0;
    margin-left: 0px;
  }
  .chooseHref {
    font-size: 14px;
    margin-top: 0;
    margin-left: 0px;
  }
  .mobHide{
    display: none;
  }
  .mobShow{
    display: block;
  }
  ::v-deep .pad {
    padding-top: 50px;
  }
}
@media only screen and (min-width: 501px) {
  .hideMob {
    display: none;
  }
}
@media only screen and (max-width: 500px) {
  .bottomHomeCard > .card {
    border: 0px solid rgba(0, 0, 0, 0.125);
    height: auto!important;
  }
  .HPbanner {
    margin-top: 0;
  }
  .showDesk {
    display: none;
  }
  .choose {
    font-size: 12px;
    margin-top: 0;
    padding: 15px 30px;
    text-align: center;
    margin: auto;
  }
  .chooseHref {
    font-size: 12px;
    margin-top: 0;
    padding: 15px 30px;
    text-align: center;
    margin: auto;
    float: none;
    display: inline-block;
  }
  h1.blockText {
    text-align: center;
    margin: 0 auto;
    padding: 0;
    /* margin-top: 50px; */
    /* margin-top: -132px; */
    margin-top: 0px;
    margin-left: 0px;
    padding-left: 0px;
    font-family: GT-Haptik-Medium;
    padding-top: 25px;
  }
  .title {
    top: 0;
    position: inherit;
    text-align: center;
    color: #000;
    margin-bottom: 0px;
    z-index: 999;
    display: inline-table;
    padding-bottom: 40px;
    margin-top: 16px;
  }
  .Jointag {
    font-weight: normal;
    font-size: 1.2rem;
    width: 100%;
    padding-bottom: 20px;
    display: block;
    margin: auto;
  }
  .joinSwimBlock {
    text-align: center;
    color: #fff;
  }
  ::v-deep .pad {
    padding-left: 0px;
    padding-right: 0px;
  }
  h2.upcomingEvents {
    font-weight: bold;
    font-size: 2rem;
    margin-top: 20px;
    margin-left: -15px;
  }
  ::v-deep  .ourContainer {
    max-width: none;
    margin: 0px auto;
    padding: 0 0px;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
  }
  .well {
    font-size: 2rem;
  }
}
@media only screen and (max-width: 480px) {
  .all > a {
    color: #009fe3;
    text-decoration: underline;
    font-weight: bold;
    font-size: 8pt;
  }
  /* .title {
    top: 7%;
  } */
  .well {
    font-size: 2rem;
  }
  .scot {
    font-size: 1.5rem;
  }
  .tag {
    font-size: 0.8rem;
  }
  /* .choose {
    font-size: 12px;
    margin-top: -8px;
    padding: 15px 30px;
  } */
}
@media only screen and (max-width: 400px) {
  /* ::v-deep .choose {
    font-size: 12px;
    margin-top: -40px;
    padding: 15px 30px;
  } */
}
</style>
